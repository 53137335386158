<template>
	<BaseLoading v-if="edit.isLoading" is-full-page />
	<VariantInfoForm
		v-else
		:variant-info="variantInfo"
		:variant-info-id="variantInfoId"
		is-edit
	/>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import VariantInfoForm from '../components/VariantInfoForm.vue';

export default {
	name: 'VariantInfoEdit',

	components: {
		VariantInfoForm,
	},

	data() {
		return {
			variantInfoId: this.$route.params.id,
		};
	},

	computed: {
		...mapState('variantInfo', {
			edit: 'edit',
		}),
		variantInfo() {
			return this.edit.data;
		},
	},

	async mounted() {
		await this.getVariantInfo(this.variantInfoId);
	},


	methods: {
		...mapActions({
			getVariantInfo: 'variantInfo/getVariantInfo',
		}),
	},
};
</script>
