
<template>
	<div>
		<form
			class="main-wrapper mt-4"
			@submit.prevent="handleSubmit"
		>
			<CRow>
				<CCol md="12">
					<h2 class="typo-h4 position-relative">
						General info
						<span class="title-caption typo-caption color-black-45">Variant info ID: <span class="typo-caption color-black-45">{{ variantInfoId || 'Generate after creates' }}</span></span>
					</h2>
				</CCol>
				<CCol md="12" class="mt-2">
					<CInput
						v-model.trim="$v.localVariantInfoName.$model"
						:is-valid="!$v.localVariantInfoName.$error && null"
						label="Variant info name*"
						placeholder="e.g. Capacity"
						invalid-feedback="Field Length: 1 - 255 characters"
					/>
				</CCol>
				<CCol md="12">
					<div class="form-group form-group-switch mt-1">
						<label class="toggle-label" for="status">Variant info status</label>
						<CSwitch
							id="status"
							:checked.sync="localVariantInfoStatus"
							variant="3d"
							size="sm"
							class="switch-custom"
							color="success"
							@update:checked="handleChangeStatus"
						/>
						<span>{{ localVariantInfoStatus ? 'Active' : 'Inactive' }}</span>
					</div>
				</CCol>
				<CCol md="12" class="mt-4">
					<hr>
				</CCol>
			</CRow>
			<CRow class="mb-4">
				<CCol md="12">
					<h2 class="typo-h4">
						Content
					</h2>
				</CCol>
				<CCol md="12" class="mt-4">
					<label>Variant info content TH*</label>
					<BaseRichTextEditor
						v-model="$v.localVariantInfoContentTH.$model"
						:is-valid="!$v.localVariantInfoContentTH.$error"
						:invalid-feedback="$t('global.error.required')"
						placeholder="e.g. This MacBook Air comes with 256GB of SSD storage.¹ You can configure it with up to 2TB of storage, giving you more space to store your documents, photos, music, and apps, as well as extensive video libraries and large data files."
					/>
				</CCol>
				<CCol md="12">
					<label>Variant info content EN*</label>
					<BaseRichTextEditor
						v-model="$v.localVariantInfoContentEN.$model"
						:is-valid="!$v.localVariantInfoContentEN.$error"
						:invalid-feedback="$t('global.error.required')"
						placeholder="e.g. This MacBook Air comes with 256GB of SSD storage.¹ You can configure it with up to 2TB of storage, giving you more space to store your documents, photos, music, and apps, as well as extensive video libraries and large data files."
					/>
				</CCol>
			</CRow>

			<BaseActionPanelStickyFooter
				:is-edit="isEdit"
				:disabled-confirm="isSubmitting"
				:button-edit-confirm-text="isSubmitting ? 'Saving...' : 'Save changes'"
				:remove-text="isEdit ? 'Remove variant info' : null"
				content-class="main-wrapper-large"
				@onConfirm="handleSubmit"
				@onCancel="$router.push({ name: 'VariantInfoList'})"
				@onRemove="isEdit ? $refs['modal-remove'].open() : null"
			/>
		</form>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteVariantInfo.bind(null, variantInfoId)"
			description="Are you sure you want to delete this variant info?"
			title="Remove this variant info?"
			is-confirm-required
			@onSuccess="$router.push({ name: 'VariantInfoList'})"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

export default {
	name: 'VariantInfoForm',

	validations: {
		localVariantInfoName: {
			required,
			maxLength: maxLength(255),
		},
		localVariantInfoStatus: {
			required,
		},
		localVariantInfoContentEN: {
			required,
		},
		localVariantInfoContentTH: {
			required,
		},
	},

	mixins: [validationMixin],

	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		variantInfo: {
			type: Object,
			default: () => {},
		},
		variantInfoId: {
			type: [String, Number],
			default: null,
		},
	},

	data() {
		const { isActive, name, contentEN, contentTH } = this.variantInfo || {};

		return {
			isSubmitting: false,
			localVariantInfoName: name || '',
			localVariantInfoStatus: Boolean(isActive),
			localVariantInfoContentEN: contentEN,
			localVariantInfoContentTH: contentTH,
		};
	},

	mounted() {
		if (this.isEdit) {
			const { isActive, name, contentEN, contentTH } = this.variantInfo || {};
			this.localVariantInfoName = name || '';
			this.localVariantInfoStatus = Boolean(isActive);
			this.localVariantInfoContentEN = contentEN;
			this.localVariantInfoContentTH = contentTH;
		}
	},

	methods: {
		...mapActions({
			createVariantInfo: 'variantInfo/createVariantInfo',
			updateVariantInfo: 'variantInfo/updateVariantInfo',
			deleteVariantInfo: 'variantInfo/deleteVariantInfo',
			showToast: 'toast/showToast',
		}),

		handleChangeStatus(value) {
			this.localVariantInfoStatus = value;
		},

		async handleSubmit() {
			this.isSubmitting = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				this.isSubmitting = false;
			} else {
				const payload = {
					...(this.isEdit ? { id: this.variantInfoId } : {}),
					name: this.localVariantInfoName,
					is_active: this.localVariantInfoStatus ? 1 : 0,
					content_en: this.localVariantInfoContentEN,
					content_th: this.localVariantInfoContentTH,
				};
				try {
					if (!this.isEdit) {
						await this.createVariantInfo(payload);
						this.$router.push({ name: 'VariantInfoList' });
					} else {
						await this.updateVariantInfo({ id: this.variantInfoId, payload });
						this.$router.push({ name: 'VariantInfoList' });
					}
				} catch (err) {
					this.showToast({
						header: this.$t('global.errorMessageTitleEdit'),
						content: this.$t('global.errorMessage'),
						type: 'danger',
					});
				} finally {
					this.isSubmitting = false;
				}
			}
		},

	},
};
</script>

<style lang="scss" scoped>
.title-caption {
	position: absolute;
	right: 0;
	bottom: rem(4);
	font-weight: 500;
}
</style>
